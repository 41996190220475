import React from "react";
import {
  FaArrowRight,
  FaBox,
  FaBoxes,
  FaCarSide,
  FaDoorOpen,
  FaLink,
  FaMoneyBill,
  FaQuestionCircle,
  FaToolbox,
  FaUsers,
} from "react-icons/fa";

const Admin = ({ user, all, gotoScreen, handleLogout }) => {
  return (
    <>
      <div className="container card t-a-c" style={{ marginTop: 10 }}>
        <p className="account-name no-padding no-margin"> {user?.name} </p>
        <p className="account-mobile no-padding no-margin"> {user?.mobile} </p>
        <p className="account-role no-padding no-margin">{user?.role_name}</p>
      </div>

      {user.role_name === "Admin" && (
        <>
          <div className="container card mt-10 flex">
            <div className="profile-card">
              <img
                className="grid-item-image"
                src={require("./../../assets/icons/box.png")}
              />
              <p> Total Category </p>
              <p className="p-color"> {all.total_category} </p>
            </div>

            <div className="profile-card">
              <img
                className="grid-item-image"
                src={require("./../../assets/icons/blog.png")}
              />
              <p> Total Posts </p>
              <p className="p-color"> {all.total_post} </p>
            </div>
          </div>

          <div className="container mt-10">
            <div className="flex" onClick={() => gotoScreen("/category")}>
              <div className="list-image">
                <FaBox />
              </div>
              <div className="list-name">
                <p> All Category</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>

            <div className="flex" onClick={() => gotoScreen("/post")}>
              <div className="list-image">
                <FaToolbox />
              </div>
              <div className="list-name">
                <p> All Post</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={() => gotoScreen("/quick-link")}>
              <div className="list-image">
                <FaLink />
              </div>
              <div className="list-name">
                <p> All Quick Links</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>

            <div className="flex" onClick={() => gotoScreen("/customers")}>
              <div className="list-image">
                <FaUsers />
              </div>
              <div className="list-name">
                <p> All Customers</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={() => gotoScreen("/group-buy")}>
              <div className="list-image">
                <FaBoxes />
              </div>
              <div className="list-name">
                <p> All Group Buys</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={() => gotoScreen("/cars")}>
              <div className="list-image">
                <FaCarSide />
              </div>
              <div className="list-name">
                <p> All Cars</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={() => gotoScreen("/faq")}>
              <div className="list-image">
                <FaQuestionCircle />
              </div>
              <div className="list-name">
                <p> All FAQ's</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={() => gotoScreen("/payment")}>
              <div className="list-image">
                <FaMoneyBill />
              </div>
              <div className="list-name">
                <p> All Payment</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={handleLogout}>
              <div className="list-image">
                <FaDoorOpen />
              </div>
              <div className="list-name">
                <p> Logout</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Admin;
