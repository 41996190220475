import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import LoaderComponent from "../../components/loader/loader";
import {
  deleteDataApi,
  getAllCustomerDataApi,
  getDataApi,
} from "./customers.service";
import SearchComponent from "../../components/search/search";
// import
const CustomerComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({
    loading: true,
    search: "",
    allTableData: [],
  });
  const [tableData, setTableData] = useState([]);

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 200,
  };
  const onload = () => {
    getData(params);
  };

  const getData = async (params) => {
    const data = await getDataApi(params);

    if (data.status) {
      setTableData(data.data);
      setAll({ ...all, loading: false, allTableData: data.data });
    } else setAll({ ...all, loading: false });
    const response = await getAllCustomerDataApi();
  };

  useEffect(() => {
    onload();
  }, []);

  const handleEdit = (customer) => {
    navigate("/customers/edit", { state: customer });
  };
  const handleDeleteClick = (customer) => {
    console.log(customer);

    Swal.fire({
      title: "Delete customer!",
      text: `Are you sure you want to delete the ${customer.name} customer?`,
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDeleteSubmit(customer.id);
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const handleDeleteSubmit = async (id) => {
    const respose = await deleteDataApi([id]);
    onload();
  };

  const handleSearch = (value) => {
    setAll({ ...all, search: value });

    if (!value) {
      setTableData(all.allTableData);
      return;
    }

    const phoneResults = all.allTableData.filter((customer) =>
      customer?.phone?.toLowerCase().includes(value.toLowerCase())
    );

    const customerResults = all.allTableData.filter((customer) =>
      customer?.name?.toLowerCase().includes(value.toLowerCase())
    );

    const locationResults = all.allTableData.filter((customer) =>
      customer?.location?.toLowerCase().includes(value.toLowerCase())
    );

    const searchRes = [...phoneResults, ...customerResults, ...locationResults];

    setTableData(searchRes);
  };

  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <AccountHeaderComponent name="Manage Customers" backRoute="/account" />
        <div className="account-content">
          <div className="container">
            <SearchComponent
              style={{ marginTop: 10 }}
              search={all.search}
              handleSearch={handleSearch}
              placeholder="Search by Name, Mobile and Location"
            />
          </div>

          {!all.loading && tableData.length === 0 && (
            <div className="container t-a-c">
              <EmptyBoxComponent />
            </div>
          )}

          {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )}

          <div>
            {tableData.map((item, i) => (
              <>
                <div
                  key={i}
                  style={{
                    margin: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "6px 5px 6px 1px",
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "15px",
                      flexGrow: 1,
                      textAlign: "left",
                      fontWeight: "normal",
                    }}
                  >
                    {item.name}
                    <p className="p-color no-margin"> {item.phone}</p>
                  </div>

                  <div style={{ marginLeft: "15px", textAlign: "right" }}>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{ cursor: "pointer", color: "#dc3545" }}
                      onClick={() => handleDeleteClick(item)}
                    />
                  </div>
                  <div style={{ marginLeft: "15px", textAlign: "right" }}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ cursor: "pointer", color: "#7c77fb" }}
                      onClick={() => handleEdit(item)}
                    />
                  </div>
                </div>
                <div> </div>
              </>
            ))}
          </div>

          <FloatingButtonComponent
            icon={<FaPlus />}
            onClick={() => gotoScreen("/customers/add")}
            position={{ bottom: "30px", right: "30px" }}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default CustomerComponent;
