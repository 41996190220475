import React from "react";
import { useNavigate } from "react-router-dom";
import { generateSlug } from "../../utils/common";
import { isMobile } from "react-device-detect";

const AppCategoriesComponent = ({ categories }) => {
  const navigate = useNavigate();

  const handleClick = (category) => {
    console.log({ category });

    navigate("/view-category/" + category.id + "/" + category.name, {
      state: category,
    });
  };
  return (
    <div className="app-categories">
      <p className="title">Categories</p>

      <div className={isMobile ? "grid-container" : "grid-container-desktop"}>
        {categories.map((category, i) => (
          <div
            className={`grid-item ${i % 2 === 0 ? "left" : "right"}`}
            onClick={() => handleClick(category)}
          >
            <img className="grid-item-image" src={category.image} />
            <p className="grid-item-text">{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppCategoriesComponent;
