import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./app-header.css";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa";
import { getAllDataApi } from "../../screens/quick-links/quick-links.service";
import SearchComponent from "../search/search";
import LinkShareButton from "../post-share/link-share-button";
import { APP_URL } from "../../constants/api.constants";

const AppHeaderComponent = ({ search, handleSearch }) => {
  const navigate = useNavigate();
  const [openPanel, setOpenPanel] = useState(false);
  const [quickLinks, setQuickLinks] = useState([]);

  const { token } = useSelector((state) => state.app);

  const handleAccountClick = () => {
    if (token) return navigate("/account");
    return navigate("/login");
  };

  const toggleMenu = () => {
    setOpenPanel(!openPanel);
  };
  const onload = async () => {
    const response = await getAllDataApi();
    console.log(response.data);
    if (response.data) setQuickLinks(response.data);
  };
  useEffect(() => {
    onload();
  }, []);

  return (
    <div className="app-header">
      <div className="app-title">
        <div onClick={() => setOpenPanel(!openPanel)}>
          <img
            className="menu-icon"
            src={require("./../../assets/icons/menu.png")}
          />
        </div>
        <div className="app-title-text">
          <p> VAG BAY </p>
        </div>
        <div className="app-user-icon" onClick={handleAccountClick}>
          <img
            className="user-icon"
            src={require("./../../assets/icons/profile-user.png")}
          />
        </div>
      </div>
      <div className="search-container">
        <div>
          <SearchComponent search={search} handleSearch={handleSearch} />
        </div>
        {search && (
          <div className="search-share">
            <LinkShareButton url={APP_URL + "search?key=" + search} />
          </div>
        )}
      </div>

      <div className={`menu ${openPanel ? "open" : ""}`}>
        <button className="close-button" onClick={toggleMenu}>
          ×
        </button>
        <ul>
          <li>
            <p className="quick-links"> QUICK LINKS</p>
          </li>

          {quickLinks.map((link, index) => (
            <li key={index} style={{ paddingTop: 18 }}>
              <div>
                <a
                  className="flex"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link.url}
                >
                  <p style={{ width: "95%" }} className="no-margin">
                    {link.name}
                  </p>
                  <FaArrowRight />
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AppHeaderComponent;
