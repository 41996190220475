import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  carCustomerRegisterAPI,
  carCustomerVerifyAPI,
} from "../cars/cars.service";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import CustomerRegistration from "../group-buy/customer-registration";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import { setUserTokenAction } from "../../reducers/app.reducer";
import { getSellerRequestInputs } from "../group-buy/group-buy.constant";

const GetCarSellerComponent = () => {
  const { user } = useSelector((state) => state.app);
  const { uniqueId } = useParams();
  const [all, setAll] = useState({
    loading: true,
    showJoinModal: false,
    memberDetails: null,
    modalLoading: false,
  });
  const navigate = useNavigate();

  const { memberDetails, modalLoading } = all;

  const [resendTimer, setResendTimer] = useState(60);
  const dispatch = useDispatch();

  const handleJoin = async () => {
    navigate(`/vrs-for-sale/${uniqueId}`);

    setAll({ ...all, showJoinModal: false, modalLoading: false });
  };

  const handleMemberJoin = async (values) => {
    setAll({ ...all, modalLoading: true });
    console.log(values);
    values = { ...values, uniqueId: uniqueId };

    const res = await carCustomerRegisterAPI(values);

    console.log({ res });
    if (res.status) {
      setAll({ ...all, memberDetails: values, modalLoading: false });
      setResendTimer(60);
    } else {
      setAll({ ...all, modalLoading: false });
      alert(res.message);
    }
  };

  const handleResendOTP = async () => {
    const res = await carCustomerRegisterAPI(memberDetails);

    console.log({ res });
    if (res.status) {
      setResendTimer(60);
    }
  };

  const handleMemberVerify = async (values) => {
    setAll({ ...all, modalLoading: true });
    console.log({ values });
    const res = await carCustomerVerifyAPI({
      ...values,
      phone: memberDetails.phone,
      uniqueId: uniqueId,
    });

    if (res.status) {
      dispatch(
        setUserTokenAction({
          ...res.data,
          user: { ...res.data.user, type: "customer" },
        })
      );
      handleJoin();
    } else {
      alert(res.message);
      setAll({ ...all, modalLoading: false });
    }
  };

  useEffect(() => {
    let timer;
    if (memberDetails && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [memberDetails, resendTimer]);

  let loggedInAdminOrUser = false;

  if (user && user.type === "customer") {
    loggedInAdminOrUser = true;
  }

  // console.log(user);

  return (
    <PageWrapperComponent>
      <AccountHeaderComponent
        name={`Get Seller Information`}
        backRoute="/vrs-for-sale"
      />

      <div style={{ paddingTop: 40 }}>
        <CustomerRegistration
          modalLoading={modalLoading}
          user={false}
          registrationInputs={getSellerRequestInputs({})}
          resendTimer={resendTimer}
          memberDetails={memberDetails}
          loggedInAdminOrUser={loggedInAdminOrUser}
          handleJoin={handleJoin}
          handleMemberVerify={handleMemberVerify}
          handleMemberJoin={handleMemberJoin}
          handleResendOTP={handleResendOTP}
        />
      </div>
    </PageWrapperComponent>
  );
};

export default GetCarSellerComponent;
