import React from "react";

const PaymentButton = ({ handleClickPay, all = {}, handleChange }) => {
  return (
    <div className="d-flex mt-10">
      <span>
        <input
          className={"search-input t-a-c w-50"}
          type={"number"}
          value={all?.amount || 399}
          name={"amount"}
          placeholder={"Enter the amount"}
          onChange={handleChange}
        />
      </span>
      <span>
        <button
          className="seller-btn h-44"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            handleClickPay();
          }}
        >
          Pay
        </button>
      </span>
    </div>
  );
};

export default PaymentButton;
