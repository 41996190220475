// import {states}
// import {  } from "@con";
import { APP } from "../../constants/app.constant";
import { customerAutoCompleteApi } from "../customers/customers.service";

const { STATES } = APP;
export const getGBMemberVerifyInputs = (defaultValues) => {
  return [
    {
      type: "TEXT",
      label: "Verification Code",
      inputType: "number",
      id: "code",
      name: "code",
      value: defaultValues?.code || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Verification code is required",
        },
      ],
    },
  ];
};

export const getGroupBuyInputs = (defaultValues) => {
  return [
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: defaultValues?.name || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required",
        },
      ],
    },
    {
      type: "TEXT",
      inputType: "number",
      label: "Mobile",
      id: "phone",
      name: "phone",
      value: defaultValues?.mobile || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Mobile is required",
        },
      ],
    },
    {
      type: "TEXT_AREA",
      label: "Address",
      id: "address",
      name: "address",
      value: defaultValues?.address || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Address is required",
        },
      ],
    },

    {
      type: "TEXT",
      label: "City",
      id: "city",
      name: "city",
      value: defaultValues?.city || "",
      validations: [
        {
          type: "REQUIRED",
          message: "City is required",
        },
      ],
    },

    {
      type: "TEXT",
      inputType: "number",
      label: "Pincode",
      id: "pincode",
      name: "pincode",
      value: defaultValues?.pincode || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Pincode is required",
        },
      ],
    },
    {
      type: "SELECT",
      label: "State",
      id: "state",
      name: "state",
      value: defaultValues?.state || "",
      placeholder: "Select the states",

      options: STATES,
      validations: [
        {
          type: "REQUIRED",
          message: "State is required",
        },
      ],
    },
  ];
};

export const getSellerRequestInputs = (defaultValues) => {
  return [
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: defaultValues?.name || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required",
        },
      ],
    },
    {
      type: "TEXT",
      inputType: "number",
      label: "Mobile",
      id: "phone",
      name: "phone",
      value: defaultValues?.mobile || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Mobile is required",
        },
      ],
    },
  ];
};

export const getGroupBuyAdminInputs = (defaultValues) => {
  return [
    {
      type: "TEXT_AUTOCOMPLETE",
      label: "Customer",
      id: "customer",
      name: "customer",
      value: defaultValues?.customer || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Customer is required",
        },
      ],
      fetchOptions: customerAutoCompleteApi,
      formatOption: (option) => `${option.name} - ${option.phone}`,
    },
  ];
};
