import React from "react";

import ButtonComponent from "../../components/button/button.component";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import LoaderComponent from "../../components/loader/loader";
import {
  getGBMemberVerifyInputs,
  getGroupBuyInputs,
} from "./group-buy.constant";

const CustomerRegistration = ({
  user,
  resendTimer,
  modalLoading,
  memberDetails,
  handleMemberVerify,
  handleMemberJoin,
  handleResendOTP,
  registrationInputs,
}) => {
  if (modalLoading)
    return (
      <div className="t-a-c" style={{ paddingTop: 50 }}>
        <LoaderComponent />
      </div>
    );

  return (
    <div className="gb-join-container">
      {!user && (
        <>
          {memberDetails && (
            <div>
              <FormBuilderComponent
                inputs={getGBMemberVerifyInputs({})}
                onSubmit={handleMemberVerify}
              />
              <div className="t-a-c">
                {resendTimer > 0 && <p> Resend otp in {resendTimer} </p>}
                {resendTimer === 0 && (
                  <p className="resend-otp" onClick={handleResendOTP}>
                    {" "}
                    Resend OTP{" "}
                  </p>
                )}
              </div>
            </div>
          )}

          {!memberDetails && (
            <FormBuilderComponent
              inputs={
                registrationInputs ? registrationInputs : getGroupBuyInputs({})
              }
              onSubmit={handleMemberJoin}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomerRegistration;
