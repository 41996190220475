import { customerAutoCompleteApi } from "../customers/customers.service";

export const getPaymentForm = (defaultValues) => {
  return [
    {
      type: "TEXT_AUTOCOMPLETE",
      label: "Customer ",
      id: "customer_id",
      name: "customer_id",
      value: defaultValues?.customer_id || "",
      validations: [
        {
          type: "REQUIRED",
          message: "Customer is required",
        },
      ],
      fetchOptions: customerAutoCompleteApi,
      formatOption: (option) => `${option.name} - ${option.phone}`,
    },
    {
      type: "TEXT",
      label: "Payment Id",
      id: "payment_id",
      name: "payment_id",
      value: defaultValues?.payment_id || "",
      placeholder: "Please Enter the  Payment Id ",
      validations: [
        {
          type: "REQUIRED",
          message: "Payment Id is required",
        },
      ],
    },
    {
      type: "TEXT",
      label: "Amount",
      id: "amount",
      name: "amount",
      value: defaultValues?.amount || "",
      placeholder: "Please Enter the Amount",
      validations: [
        {
          type: "REQUIRED",
          message: "Amount Id is required",
        },
      ],
    },

    {
      type: "FILE_UPLOAD",
      label: "Image",
      id: "file",
      name: "file",
      value: defaultValues?.file || "",
      placeholder: "Upload the image file",
      validations: [],
    },
    {
      type: "SELECT",
      label: "Status",
      id: "status",
      name: "status",
      value: defaultValues?.status || 1,
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required",
        },
      ],
      options: [
        {
          name: "Paid",
          value: 1,
        },
        {
          name: "Not Paid",
          value: 2,
        },
      ],
    },
  ];
};
