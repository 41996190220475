import React, { useEffect, useState } from "react";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import FormInputFileUploadComponent from "../../components/form-inputs/form-file-upload/form-file-upload.component";
import FormInputTextAreaComponent from "../../components/form-inputs/form-input-text/form-input-textarea.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { customerAutoCompleteApi } from "../customers/customers.service";
import FormInputTextAutoCompeleteComponent from "../../components/form-inputs/form-input-text-autocomplete/form-input-auto-complete.componet";

const CarsForm = ({ all, setAll, handleChange, handleSubmit }) => {
  const [fields, setFields] = useState([]);

  const handleFileChange = async (name, value) => {
    console.log("File selected:", name);
    const newFields = [...fields];

    newFields[name] = value;
    setFields(newFields);
    handleChange(name, value);
  };

  const addField = () => {
    setFields([...fields, { image: "" }]);
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, idx) => idx !== index);
    setFields(updatedFields);

    setAll((prevState) => ({
      ...prevState,
      images: updatedFields,
    }));
  };

  useEffect(() => {
    setFields(all.images?.length > 0 ? all.images : [{ image: "" }]);
  }, [all]);

  // console.log(all);

  return (
    <>
      <div className="account-content">
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Name"}
            name={"name"}
            onChange={handleChange}
            placeholder={"Enter the Name"}
            value={all?.name}
            isRequired={true}
          />
        </div>

        <div className="container mt-10">
          <FormInputTextAutoCompeleteComponent
            label={"Customer"}
            name={"customer_id"}
            onChange={handleChange}
            placeholder={"Select  the Customer"}
            value={all?.customer_id || ""}
            fetchOptions={customerAutoCompleteApi}
            // inputIcon={"plus"}
            formatOption={(option) => `${option.name} - ${option.phone}`}
            isRequired={true}
          />
        </div>
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Price"}
            name={"price"}
            onChange={handleChange}
            placeholder={"Enter the Price"}
            value={all?.price}
            isRequired={true}
          />
        </div>

        <div className="container mt-10">
          <FormInputTextAreaComponent
            label={"Description"}
            name={"description"}
            onChange={handleChange}
            placeholder={"Enter the Decription "}
            value={all?.description}
            isRequired={true}
          />
        </div>
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Odo"}
            name={"odo"}
            onChange={handleChange}
            placeholder={"Enter the Odo "}
            value={all?.odo}
            isRequired={false}
          />
        </div>
        <div className="container mt-10">
          <FormInputTextComponent
            label={"No Of Owners "}
            name={"no_of_owners"}
            onChange={handleChange}
            placeholder={"Enter the No Of Owners "}
            value={all?.no_of_owners}
            isRequired={false}
          />
        </div>
        <div className="container mt-10">
          {fields.map((field, index) => (
            <div
              key={index}
              className="field-container"
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              <span style={{ width: "82%" }}>
                <FormInputFileUploadComponent
                  label={`Image`}
                  name={`image`}
                  placeholder={"Upload the Category image"}
                  onChange={handleFileChange}
                />
              </span>
              <span
                className="mt-10"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: "30px",
                }}
              >
                {field?.image && (
                  <img
                    src={field.image}
                    alt="Post Preview"
                    style={{
                      width: "50px",
                      borderRadius: "10px",
                      height: "35px",
                    }}
                  />
                )}
              </span>

              <span
                className={`mt-10 add-button ${
                  index === fields.length - 1 ? "add" : "delete"
                }`}
                role="button"
                aria-label={
                  index === fields.length - 1 ? "Add field" : "Remove field"
                }
              >
                <FontAwesomeIcon
                  icon={index === fields.length - 1 ? faPlus : faTrash}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (index === fields.length - 1) {
                      addField();
                    } else {
                      removeField(index);
                    }
                  }}
                />
              </span>
            </div>
          ))}
        </div>
        <div className="container mt-10">
          <ButtonComponent text="Submit" onClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default CarsForm;
