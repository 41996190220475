import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { getAllFaqApi } from "./faq.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from "../../components/loader/loader";
import { groupBy } from "../../utils/common";
// import
import SearchComponent from "../../components/search/search";
import TabLayout from "../../components/tab-layout/tab-layout.component";
import { formatDate } from "../../utils/date.util";

const FaqPageComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({ loading: true, search: "" });
  const [tableData, setTableData] = useState([]);

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 150,
  };
  const onload = () => {
    getData(params);
  };

  const getData = async (params) => {
    const data = await getAllFaqApi();

    if (data.status) {
      setAll({ ...all, allTableData: data.data, loading: false });
      setTableData(data.data);
    } else {
      setAll({ ...all, loading: false });
    }
  };

  const handleSearch = (value) => {
    setAll({ ...all, search: value });

    if (!value) {
      setTableData(all.allTableData);
      return;
    }
    const results = tableData.filter((faq) =>
      faq.title.toLowerCase().includes(value.toLowerCase())
    );
    const tagRsults = tableData.filter((faq) =>
      faq?.tags.some((tag) =>
        tag?.value?.toLowerCase().includes(value.toLowerCase())
      )
    );

    const searchRes = [...results, ...tagRsults];

    const groupedResults = groupBy(searchRes, "id");

    setTableData(groupedResults);
  };
  const [expandedItems, setExpandedItems] = useState({});

  const toggleDescription = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  useEffect(() => {
    onload();
  }, []);

  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <div style={{ display: "flex", position: "relative" }}>
          <AccountHeaderComponent name="FAQ" backRoute="/" />
        </div>
        <div className="account-content">
          <div className="container">
            <SearchComponent
              style={{ marginTop: 10 }}
              search={all.search}
              handleSearch={handleSearch}
              placeholder="Search Tags"
            />
          </div>

          {!all.loading && tableData.length === 0 && (
            <div style={{ marginTop: 10 }} className="container t-a-c">
              <EmptyBoxComponent />
            </div>
          )}

          {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )}
          <div style={{ paddingBottom: 85 }}>
            {tableData.map((item) => (
              <div key={item.id} className="faq-container">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <span
                    style={{
                      flexGrow: 1,
                      textAlign: "left",
                      fontWeight: "normal",
                    }}
                  >
                    {item.title} ?
                  </span>
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={expandedItems[item.id] ? faCaretUp : faCaretDown}
                      onClick={() => toggleDescription(item.id)}
                    />
                  </span>
                </div>

                <div className="faq-description">
                  {expandedItems[item.id]
                    ? item.description
                    : `${item.description.slice(0, 35)}...`}
                </div>

                <div
                  className="faq-status"
                  onClick={() => toggleDescription(item.id)}
                >
                  {expandedItems[item.id] ? "Show Less" : "Show More"}
                </div>

                <div>
                  <div className="faq-description">
                    Posted By: {item.customer_name}
                  </div>
                  {item.created_at && (
                    <div className="faq-description">
                      Posted Date: {formatDate(item.created_at)}
                    </div>
                  )}
                </div>

                <div style={{ marginTop: 10 }} className="faq-tags-container">
                  {item.tags.map((tag) => (
                    <div className="faq-tag">{tag.label}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TabLayout defaultTab={"faq"} />
    </PageWrapperComponent>
  );
};

export default FaqPageComponent;
