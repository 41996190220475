import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { useNavigate } from "react-router-dom";
import { createPaymentAPI } from "./payment.service";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { getPaymentForm } from "./payment-form";

const AddPaymentComponent = ({
  isShowheader = true,
  customer = {},
  setClose,
  setAll,
}) => {
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  console.log(setAll);

  const handleSubmit = async (values) => {
    const resp = await createPaymentAPI(values);
    // console.log(resp.data);

    if (resp.status) {
      // handleChange()
      // setAll((prevState) => ({
      //   ...prevState,
      //   ["category_id"]: resp.data?.id,
      // }));

      // handleChange(customerData, resp.data?.id);

      if (!isShowheader) {
        setAll((prevState) => ({
          ...prevState,
          customer_id: resp.data?.id,
        }));
        setClose(false);
      } else {
        gotoScreen("/payment");
      }
    }
  };

  return (
    <PageWrapperComponent>
      <div className="page-container">
        {isShowheader && (
          <AccountHeaderComponent name="Add Payment" backRoute="/payment" />
        )}

        <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
          <FormBuilderComponent
            onSubmit={handleSubmit}
            inputs={getPaymentForm(customer)}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AddPaymentComponent;
