import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { groupBy, isAdmin } from "../../utils/common";
import PriceComponent from "../../components/price/price";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import { getAllCarsApi } from "../cars/cars.service";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import LoaderComponent from "../../components/loader/loader";
import VrsCarLinkShareComponent from "./vrs-car-share";
import { useSelector } from "react-redux";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { isMobile } from "react-device-detect";
import TabLayout from "../../components/tab-layout/tab-layout.component";
import SearchComponent from "../../components/search/search";

const VrsCarsComponent = () => {
  const [cars, setCars] = useState([]);
  const { user } = useSelector((state) => state.app);
  const [all, setAll] = useState({ loading: true, search: "" });

  const navigate = useNavigate();

  const handleClick = (cars) => {
    navigate(`/vrs-for-sale/${cars.link}`);
  };

  const handleEditPost = (cars) => {
    navigate("/cars/edit", { state: cars });
  };
  const onload = async () => {
    const response = await getAllCarsApi();
    if (response.status) {
      setCars(response.data);
      setAll({ ...all, allTableData: response.data, loading: false });
    }
  };
  useEffect(() => {
    onload();
  }, []);

  const SharComponent = ({ car }) => {
    let currentCustomerJoinedId = null;
    let loggedInAdmin = false;
    let loggedInAdminOrUser = false;
    if (user && user.role_name === "Admin") loggedInAdmin = true;

    if (
      user &&
      user.role_name === "Admin" &&
      user.customer &&
      car?.customer_requests
    ) {
      console.log(car?.customer_requests);

      loggedInAdminOrUser = true;
      currentCustomerJoinedId = car?.customer_requests.find(
        (member) => member.customer_id === user.customer.id
      );
    }

    // if (user && user.type === "customer" && car?.customer_requests) {
    //   loggedInAdminOrUser = true;
    //   // console.log(car?.customer_requests);

    //   currentCustomerJoinedId = car?.customer_requests.find(
    //     (member) => member.customer_id === user.customer_id
    //   );
    // }
    // console.log(user?.customer_id);

    // console.log(currentCustomerJoinedId);
    return (
      <VrsCarLinkShareComponent
        details={{
          link: car?.link,
          phone: car?.customer_phone,
          user: currentCustomerJoinedId || loggedInAdmin,
        }}
      />
    );
  };
  const handleSearch = (value) => {
    // console.log(cars);
    setAll({ ...all, search: value });

    if (!value) {
      setCars(all.allTableData);
      return;
    }
    const results = cars.filter((car) =>
      car?.name.toLowerCase().includes(value.toLowerCase())
    );
    const customerResults = cars.filter((car) =>
      car?.customer_name
        ? car.customer_name.toLowerCase().includes(value.toLowerCase())
        : false
    );

    const searchRes = [...results, ...customerResults];
    const groupedResults = groupBy(searchRes, "id");

    // setTableData(groupedResults);
    setCars(groupedResults);
  };
  // const SearchComponent = ({ search, style, handleSearch, placeholder }) => {
  //   return (
  //     <div style={style} className="app-search">
  //       <input
  //         className="search-input"
  //         type="text"
  //         name="search"
  //         id="search"
  //         value={search}
  //         onChange={(e) => handleSearch(e.target.value)}
  //         placeholder={placeholder}
  //       />
  //     </div>
  //   );
  // };
  return (
    <PageWrapperComponent>
      <AccountHeaderComponent name="Cars" backRoute="/" />
      <div className="account-content">
        <SearchComponent
          style={{ marginTop: 10 }}
          search={all.search}
          handleSearch={handleSearch}
          placeholder={"Search Cars"}
        />
      </div>
      {all?.loading && (
        <div className="t-a-c" style={{ paddingTop: 125 }}>
          <LoaderComponent
            path={require("./../../assets/icons/loading-gif.gif")}
          />
        </div>
      )}

      <div className="app-cars">
        {!all?.loading && cars.length === 0 && (
          <div style={{ marginTop: 10 }} className="container t-a-c">
            <EmptyBoxComponent />
          </div>
        )}

        <div className={isMobile ? "grid-container" : "grid-container-desktop"}>
          {cars.map((car, i) => (
            <div className="grid-product-item left">
              <div onClick={() => handleClick(car)}>
                {car?.images?.length > 0 && (
                  <img
                    className="grid-product-item-image"
                    src={car?.images[0]?.image}
                  />
                )}

                {!car.images && (
                  <div className="t-a-c">
                    <img
                      className="grid-product-item-image no-image"
                      src={require("./../../assets/icons/no-photos.png")}
                    />
                  </div>
                )}

                <div className="grid-content">
                  <div>
                    <p className="grid-item-text">{car.name}</p>
                    {/* <p className="grid-item-price">
                    <PriceComponent price={post.price} />
                  </p> */}
                  </div>
                  {}
                  <p
                    className="grid-item-text mt-1"
                    style={{
                      height: car?.odo ? "auto" : "20px",
                    }}
                  >
                    {car?.odo ? <>Odo : {car.odo}</> : " "}
                  </p>
                  <p
                    className="grid-item-text mt-1"
                    style={{
                      height: car?.no_of_owners ? "auto" : "20px",
                    }}
                  >
                    {car?.no_of_owners ? (
                      <>No Of Owners : {car.no_of_owners}</>
                    ) : (
                      " "
                    )}
                  </p>

                  <div className="grid-item-price">
                    <div className="p-color f-20">
                      <PriceComponent price={car.price} priceType="L" />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <SharComponent car={car} />
              </div>

              {isAdmin() && (
                <div
                  style={{ marginTop: 30 }}
                  onClick={() => handleEditPost(car)}
                >
                  <p className="no-margin post-edit">
                    <span style={{ marginRight: 5 }}>Edit</span>
                    <FaEdit style={{ marginTop: 2 }} />
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <TabLayout defaultTab={"cars"} />
    </PageWrapperComponent>
  );
};

export default VrsCarsComponent;
