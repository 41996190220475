import "./App.css";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import HomeComponent from "./screens/home/index.component";
import LoginComponent from "./screens/login/login.component";
import AccountComponent from "./screens/account/account.component";
import CategoryComponent from "./screens/category/category.component";
import AddCategoryComponent from "./screens/category/add-category.component";
import PostCreateComponent from "./screens/post/post-create.component";
import PostComponent from "./screens/post/post.component";
import EditCategoryComponent from "./screens/category/edit-category.component";
import PostEditComponent from "./screens/post/post-edit.component";
import QuickLinkComponent from "./screens/quick-links/quick-links.component";
import AddQuickLinkComponent from "./screens/quick-links/add-quick-links.component";
import EditQuickLinkComponent from "./screens/quick-links/edit-quick-links.component";
import ViewCategoryProductsComponent from "./components/app-categories/view-category-product.component";
import CustomerComponent from "./screens/customers/customers.component";
import AddCustomerComponent from "./screens/customers/add-customers.component";
import EditCustomerComponent from "./screens/customers/edit-customers.component";
import CustomerProductsComponent from "./components/customer-products/customer-product.component";
import ViewPostComponent from "./screens/view-post/view-post";
import GroupByComponent from "./screens/group-buy-admin/group-by.component";
import AddGroupByComponent from "./screens/group-buy-admin/add-group-by.component";
import EditGroupByComponent from "./screens/group-buy-admin/edit-group-by.component";
import GroupBuyScreen from "./screens/group-buy/group-buy.screen";
import AddProductsGroupByComponent from "./screens/group-buy-admin/add-products.components";
import GroupBuyJoinComponent from "./screens/group-buy/join/group-buy-join";
import GroupBuyPaymentComponent from "./screens/group-buy/payment/group-buy-payment";
import DealersScreen from "./screens/dealers/dealers.screen";
import CarsComponent from "./screens/cars/cars.component";
import CarsCreateComponent from "./screens/cars/cars-create.component";
import CarsEditComponent from "./screens/cars/cars-edit.component";
import VrsCarsComponent from "./screens/vrs-cars/vrs-cars.component";
import ViewVrsCarsComponent from "./screens/vrs-cars/view-vrs-cars.component";
import GetCarSellerComponent from "./screens/vrs-cars/vrs-cars-request-join";
import FaqComponent from "./screens/faq/faq.component";
import FaqCreateComponent from "./screens/faq/faq-create.component";
import FaqEditComponent from "./screens/faq/faq-edit.component";
import PaymentComponent from "./screens/payment/payment.component";
import AddPaymentComponent from "./screens/payment/add-payment.component";
import EditPaymentComponent from "./screens/payment/edit-payment.component";
import FaqPageComponent from "./screens/faq/faq-page.component";
import PaymentPageComponent from "./screens/payment/payment-page.component";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponent />}></Route>
        <Route path="/search" element={<HomeComponent />}></Route>
        <Route path="/login" element={<LoginComponent />}></Route>
        <Route path="/account" element={<AccountComponent />}></Route>
        <Route path="/category" element={<CategoryComponent />}></Route>
        <Route path="/dealers" element={<DealersScreen />}></Route>
        <Route path="/category/add" element={<AddCategoryComponent />}></Route>
        <Route
          path="/category/edit"
          element={<EditCategoryComponent />}
        ></Route>

        <Route path="/post" element={<PostComponent />}></Route>

        <Route path="/post/add" element={<PostCreateComponent />}></Route>
        <Route path="/post/edit/" element={<PostEditComponent />}></Route>

        <Route path="/quick-link" element={<QuickLinkComponent />}></Route>

        <Route
          path="/quick-link/add"
          element={<AddQuickLinkComponent />}
        ></Route>
        <Route
          path="/quick-link/edit/"
          element={<EditQuickLinkComponent />}
        ></Route>

        <Route
          path="/view-post/:uniqueId"
          element={<ViewPostComponent />}
        ></Route>
        <Route path="/post/:uniqueId" element={<ViewPostComponent />}></Route>
        <Route
          path="/view-customer-post/:uniqueId"
          element={<CustomerProductsComponent />}
        ></Route>
        <Route
          path="/view-category/:id/:name"
          element={<ViewCategoryProductsComponent />}
        ></Route>

        <Route path="/customers" element={<CustomerComponent />}></Route>

        <Route path="/customers/add" element={<AddCustomerComponent />}></Route>
        <Route
          path="/customers/edit/"
          element={<EditCustomerComponent />}
        ></Route>
        <Route path="/group-buy" element={<GroupByComponent />}></Route>
        <Route path="/group-buy/add" element={<AddGroupByComponent />}></Route>
        <Route
          path="/group-buy/edit/"
          element={<EditGroupByComponent />}
        ></Route>

        <Route path="/group-buy/:uniqueId" element={<GroupBuyScreen />}></Route>
        <Route
          path="/group-buy/:uniqueId/join"
          element={<GroupBuyJoinComponent />}
        ></Route>

        <Route
          path="/group-buy/:uniqueId/payment"
          element={<GroupBuyPaymentComponent />}
        ></Route>

        <Route
          path="/group-buy/products/:uniqueId"
          element={<AddProductsGroupByComponent />}
        ></Route>

        <Route path="/cars" element={<CarsComponent />}></Route>

        <Route path="/cars/add" element={<CarsCreateComponent />}></Route>
        <Route path="/cars/edit/" element={<CarsEditComponent />}></Route>

        <Route path="/vrs-for-sale" element={<VrsCarsComponent />}></Route>
        <Route
          path="/vrs-for-sale/:link"
          element={<ViewVrsCarsComponent />}
        ></Route>
        <Route
          path="/vrs-for-sale/:uniqueId/join"
          element={<GetCarSellerComponent />}
        ></Route>

        <Route path="/faq" element={<FaqComponent />}></Route>

        <Route path="/faq/add" element={<FaqCreateComponent />}></Route>
        <Route path="/faq/edit/" element={<FaqEditComponent />}></Route>

        <Route path="/payment" element={<PaymentComponent />}></Route>

        <Route path="/payment/add" element={<AddPaymentComponent />}></Route>
        <Route path="/payment/edit/" element={<EditPaymentComponent />}></Route>

        <Route path="/faq's" element={<FaqPageComponent />}></Route>
        <Route path="/payments" element={<PaymentPageComponent />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
