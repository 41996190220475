import React, { useEffect, useState } from "react";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import FormInputTextAreaComponent from "../../components/form-inputs/form-input-text/form-input-textarea.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { customerAutoCompleteApi } from "../customers/customers.service";
import FormInputTextAutoCompeleteComponent from "../../components/form-inputs/form-input-text-autocomplete/form-input-auto-complete.componet";
import { getAllFaqTagsApi, tagsAutoCompleteApi } from "./faq.service";
import { Sheet } from "react-modal-sheet";
import AddCustomerComponent from "../customers/add-customers.component";
import FeatherIcon from "feather-icons-react";
import FormInputSelectMultiCreateComponent from "../../components/form-inputs/form-input-select-multi-create/form-input-select-multi-create.component";
const FaqForm = ({ all, setAll, handleChange, handleSubmit }) => {
  const [tags, setTags] = useState([]);

  const [openSheet, setOpenSheet] = useState(false);
  const [customer, setCustomer] = useState({});

  const onLoad = async () => {
    const response = await getAllFaqTagsApi();
    // console.log(response);

    let formatTags = response?.data.map((tags) => {
      return {
        label: tags.tag,
        value: tags.tag,
      };
    });
    // console.log(formatTags);
    setTags(formatTags);
  };
  useEffect(() => {
    // console.log(all.tags);
    setTags(all.tags?.length > 0 ? all.tags : [{ tag: "" }]);
  }, [all]);
  useEffect(() => {
    onLoad();
  }, []);
  const handleInputIconClick = (value) => {
    console.log("Icon clicked", value);
    setCustomer((prevState) => ({
      ...prevState,
      name: value,
    }));
    setOpenSheet(true);
  };

  // console.log(fields);

  return (
    <>
      <div className="account-content">
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Title"}
            name={"title"}
            onChange={handleChange}
            placeholder={"Enter the Name"}
            value={all?.title}
            isRequired={true}
          />
        </div>

        <div className="container mt-10">
          <FormInputTextAutoCompeleteComponent
            label={"Customer"}
            name={"customer_id"}
            onChange={handleChange}
            placeholder={"Select  the Customer"}
            value={all?.customer_id || ""}
            fetchOptions={customerAutoCompleteApi}
            handleInputIconClick={handleInputIconClick}
            inputIcon={"plus"}
            formatOption={(option) => `${option.name} - ${option.phone}`}
            isRequired={true}
          />
        </div>

        <div className="container mt-10">
          <FormInputTextAreaComponent
            label={"Description"}
            name={"description"}
            onChange={handleChange}
            placeholder={"Enter the Decription "}
            value={all?.description}
            isRequired={true}
          />
        </div>

        <div className="container mt-10">
          <FormInputSelectMultiCreateComponent
            label={"Select Tags"}
            name={"tags"}
            placeholder={"Select the tags"}
            value={all?.tags}
            onChange={handleChange}
            options={tags}
          />
        </div>
        <div className="container mt-10">
          <ButtonComponent text="Submit" onClick={handleSubmit} />
        </div>

        <Sheet
          isOpen={openSheet}
          onClose={() => setOpenSheet(false)}
          snapPoints={[1.0]}
        >
          <Sheet.Container style={{ backgroundColor: "#f5f5f9" }}>
            <Sheet.Header>
              <p className="no-padding no-margin mt-30 t-a-c ">
                Create Customer
                <span style={{ float: "right" }}>
                  <button
                    className="cancel-button"
                    onClick={() => setOpenSheet(false)}
                  >
                    <FeatherIcon icon={"x"} width={30} />
                  </button>
                </span>
              </p>
            </Sheet.Header>

            <Sheet.Content>
              <AddCustomerComponent
                isShowheader={false}
                customer={customer}
                setClose={setOpenSheet}
                setAll={setAll}
              />
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop onClick={() => setOpenSheet(false)} />
        </Sheet>
      </div>
    </>
  );
};

export default FaqForm;
