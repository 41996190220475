import React from "react";
import CreatableSelect from "react-select/creatable"; // Correct import

const FormInputSelectMultiCreateComponent = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  options = [],
  isRequired,
}) => {
  // console.log(options);

  // options = [
  //   { value: "apple", label: "Apple" },
  //   { value: "orange", label: "Orange" },
  // ];
  const handleChange = (selectedOptions) => {
    // console.log({ selectedOptions });
    if (onChange)
      selectedOptions.forEach((obj) => {
        delete obj.__isNew__;
      });
    onChange(name, selectedOptions);
  };

  return (
    <div className="form-input-container">
      {label && (
        <p className="no-margin form-input-label">
          {label} <span className="color-red">{isRequired ? "*" : ""}</span>
        </p>
      )}
      <div>
        <CreatableSelect
          isMulti
          options={options}
          className="search-input h-auto mt-10"
          style={{ height: "auto" }}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default FormInputSelectMultiCreateComponent;
