import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { deleteDataApi, getDataApi } from "./cars.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faDownload,
  faEdit,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import LoaderComponent from "../../components/loader/loader";
import { groupBy } from "../../utils/common";
// import
import SearchComponent from "../../components/search/search";
import { API } from "../../constants/api.constants";

const CarsComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({ loading: true, search: "" });
  const [tableData, setTableData] = useState([]);
  const { CARS_DOWNLOAD } = API;

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 150,
  };
  const onload = () => {
    getData(params);
  };

  const getData = async (params) => {
    const data = await getDataApi(params);

    if (data.status) {
      setAll({ ...all, allTableData: data.data, loading: false });
      setTableData(data.data);
    } else {
      setAll({ ...all, loading: false });
    }
  };

  const handleSearch = (value) => {
    setAll({ ...all, search: value });

    if (!value) {
      setTableData(all.allTableData);
      return;
    }
    const results = tableData.filter((cars) =>
      cars.name.toLowerCase().includes(value.toLowerCase())
    );

    const customerResults = all.allTableData.filter((cars) =>
      cars?.customer && typeof cars.customer === "string"
        ? cars.customer.toLowerCase().includes(value.toLowerCase())
        : false
    );

    const searchRes = [...results, ...customerResults];

    const groupedResults = groupBy(searchRes, "id");

    setTableData(groupedResults);
  };

  useEffect(() => {
    onload();
  }, []);

  const handleEdit = (car) => {
    // gotoScreen("/post/edit");
    navigate("/cars/edit", { state: car });
  };
  const handleDeleteClick = (car) => {
    console.log(car);

    Swal.fire({
      title: "Delete car!",
      text: `Are you sure you want to delete the ${car.name} car?`,
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDeleteSubmit(car.id);
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const handleDeleteSubmit = async (id) => {
    const respose = await deleteDataApi([id]);
    onload();
  };
  const handleDownload = async () => {
    window.open(`${CARS_DOWNLOAD}`);
  };

  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <div style={{ display: "flex", position: "relative" }}>
          <AccountHeaderComponent name="Manage Cars" backRoute="/account" />
          <FontAwesomeIcon
            icon={faDownload}
            size="lg"
            onClick={() => handleDownload()}
            className="app-color"
            style={{
              position: "absolute",
              left: "80%",
              transform: "translate(-50%, 90%)",
            }}
          />
          <FontAwesomeIcon
            icon={faCar}
            size="lg"
            onClick={() => {
              navigate("/vrs-for-sale");
            }}
            className="red-color"
            style={{
              position: "absolute",
              left: "93%",
              transform: "translate(-50%, 90%)",
            }}
          />
        </div>
        <div className="account-content">
          <div className="container">
            <SearchComponent
              style={{ marginTop: 10 }}
              search={all.search}
              handleSearch={handleSearch}
            />
          </div>

          {!all.loading && tableData.length === 0 && (
            <div style={{ marginTop: 10 }} className="container t-a-c">
              <EmptyBoxComponent />
            </div>
          )}

          {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )}

          <div style={{ paddingBottom: 85 }}>
            {tableData.map((item) => (
              <div
                key={item.id}
                style={{
                  margin: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#fff",
                }}
              >
                <div className="grid-product-item " style={{ width: "auto" }}>
                  <div>
                    {item?.images?.length > 0 && (
                      <img
                        className="grid-product-item-image-post"
                        src={item?.images[0]?.image}
                      />
                    )}

                    {!item.images && (
                      <div className="t-a-c">
                        <img
                          className="grid-product-item-image-post"
                          src={require("./../../assets/icons/no-photos.png")}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <span
                  style={{
                    marginLeft: "15px",
                    flexGrow: 1,
                    textAlign: "left",
                    fontWeight: "normal",
                  }}
                >
                  {item.name}
                </span>
                <span style={{ marginLeft: "15px", textAlign: "right" }}>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ cursor: "pointer", color: "#dc3545" }}
                    onClick={() => handleDeleteClick(item)}
                  />
                </span>
                <span style={{ marginLeft: "15px", textAlign: "right" }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ cursor: "pointer", color: "#7c77fb" }}
                    onClick={() => handleEdit(item)}
                  />
                </span>
              </div>
            ))}
          </div>

          <FloatingButtonComponent
            icon={<FaPlus />}
            onClick={() => gotoScreen("/cars/add")}
            position={{ bottom: "30px", right: "30px" }}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default CarsComponent;
