import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { BUCKET_URL } from "../../constants/api.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { imageUploadAPI } from "../category/category.service";
import { editApi } from "./faq.service";
import FaqForm from "./faq-form";

const FaqEditComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };

  const [all, setAll] = useState({
    id: "",
    title: "",
    tags: [],
    customer_id: "",
  });

  const handleChange = async (name, value) => {
    console.log(name, value);

    if (typeof name === "object" && name !== null) {
      setAll({ ...all, customer_id: name.value });
    } else {
      setAll({ ...all, [name]: value });
    }
  };

  // console.log(all.images);

  const handleSubmit = async () => {
    console.log(all);
    const resp = await editApi(all?.id, all);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/faq");
    } else {
      alert(resp.message);
    }
  };

  useEffect(() => {
    console.log(state);

    setAll(state);
  }, [state, setAll]);
  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Faq" backRoute="/faq" />
        <FaqForm
          all={all}
          setAll={setAll}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </PageWrapperComponent>
  );
};
export default FaqEditComponent;
