import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { deleteDataApi, getDataApi } from "./faq.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCircleQuestion,
  faEdit,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import LoaderComponent from "../../components/loader/loader";
import { groupBy } from "../../utils/common";
// import
import SearchComponent from "../../components/search/search";

const FaqComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({ loading: true, search: "" });
  const [tableData, setTableData] = useState([]);

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 150,
  };
  const onload = () => {
    getData(params);
  };

  const getData = async (params) => {
    const data = await getDataApi(params);

    if (data.status) {
      setAll({ ...all, allTableData: data.data, loading: false });
      setTableData(data.data);
    } else {
      setAll({ ...all, loading: false });
    }
  };

  const handleSearch = (value) => {
    setAll({ ...all, search: value });

    if (!value) {
      setTableData(all.allTableData);
      return;
    }
    const results = tableData.filter((faq) =>
      faq.title.toLowerCase().includes(value.toLowerCase())
    );

    const customerResults = all.allTableData.filter((faq) =>
      faq?.customer_name && typeof faq.customer_name === "string"
        ? faq.customer_name.toLowerCase().includes(value.toLowerCase())
        : false
    );

    const searchRes = [...results, ...customerResults];

    const groupedResults = groupBy(searchRes, "id");

    setTableData(groupedResults);
  };

  useEffect(() => {
    onload();
  }, []);

  const handleEdit = (car) => {
    // gotoScreen("/post/edit");
    navigate("/faq/edit", { state: car });
  };
  const handleDeleteClick = (car) => {
    console.log(car);

    Swal.fire({
      title: "Delete car!",
      text: `Are you sure you want to delete the ${car.name} car?`,
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDeleteSubmit(car.id);
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const handleDeleteSubmit = async (id) => {
    const respose = await deleteDataApi([id]);
    onload();
  };

  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <div style={{ display: "flex", position: "relative" }}>
          <AccountHeaderComponent name="Manage Faq's" backRoute="/account" />

          <FontAwesomeIcon
            icon={faCircleQuestion}
            size="lg"
            onClick={() => {
              navigate("/faq's");
            }}
            className="app-color"
            style={{
              position: "absolute",
              left: "93%",
              transform: "translate(-50%, 90%)",
            }}
          />
        </div>
        <div className="account-content">
          <div className="container">
            <SearchComponent
              style={{ marginTop: 10 }}
              search={all.search}
              handleSearch={handleSearch}
            />
          </div>

          {!all.loading && tableData.length === 0 && (
            <div style={{ marginTop: 10 }} className="container t-a-c">
              <EmptyBoxComponent />
            </div>
          )}

          {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )}

          <div style={{ paddingBottom: 85 }}>
            {tableData.map((item) => (
              <div
                key={item.id}
                style={{
                  margin: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#fff",
                }}
              >
                <span
                  style={{
                    marginLeft: "15px",
                    flexGrow: 1,
                    textAlign: "left",
                    fontWeight: "normal",
                  }}
                >
                  {item.title}
                </span>
                <span style={{ marginLeft: "15px", textAlign: "right" }}>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ cursor: "pointer", color: "#dc3545" }}
                    onClick={() => handleDeleteClick(item)}
                  />
                </span>
                <span style={{ marginLeft: "15px", textAlign: "right" }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ cursor: "pointer", color: "#7c77fb" }}
                    onClick={() => handleEdit(item)}
                  />
                </span>
              </div>
            ))}
          </div>

          <FloatingButtonComponent
            icon={<FaPlus />}
            onClick={() => gotoScreen("/faq/add")}
            position={{ bottom: "30px", right: "30px" }}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default FaqComponent;
