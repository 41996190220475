import React from "react";

const PriceComponent = ({ price, priceType = "K" }) => {
  if (price == 1) return <div className="on-request">Price on Request </div>;
  return (
    <div>
      ₹{price}
      {priceType}
    </div>
  );
};

export default PriceComponent;
