import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

export const analytics = Analytics({
  app: "vagbay-app",
  plugins: [
    googleAnalytics({
      measurementIds: ["G-B1ZMVRLWE5"],
    }),
  ],
});
