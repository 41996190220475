import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { BUCKET_URL } from "../../constants/api.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { imageUploadAPI } from "../category/category.service";
import CarsForm from "./cars-form";
import { editApi } from "./cars.service";

const CarsEditComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [categories, setCategories] = useState([
    { name: "Set the categories", value: "" },
  ]);

  const [all, setAll] = useState({
    id: "",
    name: "",
    price: "",
    description: "",
    images: [],
    customer_id: "",
    odo: "",
    no_of_owners: "",
  });

  const handleChange = async (name, value) => {
    console.log(name, value);

    if (typeof name === "string" && name.startsWith("image")) {
      const index = parseInt(name.replace("image", ""), 10);

      try {
        const resp = await imageUploadAPI(value);
        const imageUrl = BUCKET_URL + "/app-source/images/" + resp?.data;

        const newImages = Array.isArray(all.images) ? [...all.images] : [];

        if (index >= 0 && index < newImages.length) {
          newImages[index] = { image: imageUrl };
        } else {
          newImages.push({ image: imageUrl });
        }

        setAll({ ...all, images: newImages });
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    } else if (typeof name === "object" && name !== null) {
      setAll({ ...all, customer_id: name.value });
    } else {
      setAll({ ...all, [name]: value });
    }
  };

  // console.log(all.images);

  const handleSubmit = async () => {
    // console.log(all);

    const resp = await editApi(all?.id, all);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/cars");
    } else {
      alert(resp.message); // Show the message from the response in an alert
    }
  };

  useEffect(() => {
    console.log(state);

    setAll(state);
  }, [state, setAll]);
  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Car" backRoute="/cars" />
        <CarsForm
          all={all}
          setAll={setAll}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </PageWrapperComponent>
  );
};
export default CarsEditComponent;
