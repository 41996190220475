import React, { useEffect, useRef } from "react";
// import { isMobile } from "react-device-detect";
// import OnlyMobileComponent from "../only-mobile/only-mobile.component";
import { analytics } from "../../utils/google-analytics";
import { useLocation } from "react-router-dom";

const PageWrapperComponent = ({ children }) => {
  // if (!isMobile) {
  //   return <OnlyMobileComponent />;
  // }

  const location = useLocation();
  const lastPathname = useRef(null);

  useEffect(() => {
    if (lastPathname.current !== location.pathname) {
      analytics.page();
      lastPathname.current = location.pathname;
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default PageWrapperComponent;
