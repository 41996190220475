import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick"; // Import the slider component
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { formatDate } from "../../utils/date.util";
import LoaderComponent from "../../components/loader/loader";
import PriceComponent from "../../components/price/price";

import EmptyBoxComponent from "../../components/empty-box/empty-box";
import {
  getAllCarsApi,
  getByLinkApi,
  getRelatedCarsApi,
} from "../cars/cars.service";
import { useDispatch, useSelector } from "react-redux";
import VrsCarLinkShareComponent from "./vrs-car-share";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import { isMobile } from "react-device-detect";
import { createPageEntryAPI } from "../post/post.service";

const ViewVrsCarsComponent = () => {
  const { state } = useLocation();
  const params = useParams();

  const [activeCarDetails, setActiveCarDetails] = useState();
  const [allCars, setAllCarsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const location = useLocation();

  const { link } = params;

  const navigate = useNavigate();
  const onLoad = async () => {
    const resp = await getByLinkApi(link);

    if (resp.status) {
      setActiveCarDetails(resp?.data);
      const carsRes = await getRelatedCarsApi(link);

      if (carsRes.status);
      // console.log(carsRes.data);
      // const activeCar = carsRes.data.find((cars) => cars.link === link);
      // const filteredCars = carsRes.data.filter((car) => car.link !== link);
      setAllCarsData(carsRes?.data);
    }

    setLoading(false);
    const currentPath = location.pathname;
    let payload = {
      url: currentPath,
    };
    console.log(payload);
    const pageEntry = await createPageEntryAPI(payload);
    console.log(pageEntry);
  };
  useEffect(() => {
    onLoad();
  }, [params]);

  const handleClick = (car) => {
    setLoading(true);

    // console.log(posts);
    // navigate("/view-post", { state: post });
    navigate(`/vrs-for-sale/${car.link}`);
  };
  let currentCustomerJoinedId = null;
  let loggedInAdmin = false;
  let loggedInAdminOrUser = false;
  if (user && user.role_name === "Admin") loggedInAdmin = true;

  if (user && user.role_name === "Admin" && user.customer) {
    loggedInAdminOrUser = true;
    currentCustomerJoinedId = activeCarDetails?.customer_requests.find(
      (member) => member.customer_id === user.customer.id
    );
  }

  if (user && user.type === "customer") {
    loggedInAdminOrUser = true;
    currentCustomerJoinedId = activeCarDetails?.customer_requests.find(
      (member) => member.customer_id === user.customer_id
    );
    // console.log(user?.customer_id);

    // console.log(currentCustomerJoinedId);
  }
  // console.log(activeCarDetails?.customer_requests);
  // console.log(currentCustomerJoinedId, loggedInAdmin, loggedInAdminOrUser);

  const CustomerForm = () => {
    return (
      <>
        {currentCustomerJoinedId || loggedInAdmin ? (
          <>
            <div
              style={{
                fontSize: 18,
                marginTop: 26,
                marginBottom: 15,
              }}
              className="f-bold t-a-c"
            >
              Seller Information
            </div>
            <div
              className="seller-information"
              // onClick={() => handleCustomersClick(activeCarDetails)}
            >
              <span className="contact">
                <img
                  style={{ height: 65 }}
                  className="contact-profile"
                  src={require("./../../assets/icons/profile.png")}
                  alt="Profile"
                />
                <div className="contact-details">
                  <h2>{activeCarDetails?.customer_name}</h2>
                  <h2>{activeCarDetails?.customer_phone}</h2>
                </div>
              </span>
            </div>
          </>
        ) : (
          <p>
            <button
              onClick={() => {
                navigate(`/vrs-for-sale/${activeCarDetails?.link}/join`);
              }}
              className="seller-btn"
            >
              Get Seller Information
            </button>
          </p>
        )}
      </>
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (loading)
    return (
      <div className="t-a-c" style={{ paddingTop: 125 }}>
        <LoaderComponent
          path={require("./../../assets/icons/loading-gif.gif")}
        />
      </div>
    );

  let linkShareDetails = {
    link: activeCarDetails?.link,
    phone: activeCarDetails?.customer_phone,
    user: currentCustomerJoinedId || loggedInAdmin,
  };

  return (
    <PageWrapperComponent>
      <div className="view-product">
        <AccountHeaderComponent name="Car Detail" backRoute="/vrs-for-sale" />{" "}
        <div style={{ paddingTop: 5 }}>
          {activeCarDetails == null && (
            <div className="container t-a-c" style={{ marginTop: 70 }}>
              <EmptyBoxComponent message="The post you're looking for has been deleted" />
            </div>
          )}

          {activeCarDetails && (
            <div style={{ padding: "20px", paddingTop: "55px" }}>
              <div>
                <div>
                  <div className={isMobile ? "" : "desktop-container"}>
                    {activeCarDetails.images &&
                    activeCarDetails.images.length > 0 ? (
                      <Slider {...settings}>
                        {activeCarDetails.images.map((img, index) => (
                          <div key={index} className="view-cars">
                            <img
                              className="post-sliders"
                              src={img.image}
                              alt={`Post Image ${index + 1}`}
                            />
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <img
                        className="grid-product-item-image"
                        style={{ width: "60%", marginLeft: "20%" }}
                        src={require("./../../assets/icons/no-photos.png")}
                        alt="No Images Available"
                      />
                    )}

                    <div
                      style={{ marginTop: 10, justifyContent: "space-between" }}
                      className="flex header-share"
                    >
                      <div className="f-18">Name</div>
                      <div className="p-color f-20">
                        {activeCarDetails.name}
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 10, justifyContent: "space-between" }}
                      className="flex "
                    >
                      <div className="f-18">Price</div>

                      <div className="p-color f-20">
                        {activeCarDetails.price == 1 ? (
                          <>Price on Request</>
                        ) : (
                          <>₹{activeCarDetails.price}L</>
                        )}
                      </div>
                    </div>
                    {activeCarDetails?.odo && (
                      <div
                        style={{
                          marginTop: 10,
                          justifyContent: "space-between",
                        }}
                        className="flex "
                      >
                        <div className="f-18">Odo</div>

                        <div className="p-color f-20">
                          {activeCarDetails?.odo}
                        </div>
                      </div>
                    )}
                    {activeCarDetails?.no_of_owners && (
                      <div
                        style={{
                          marginTop: 10,
                          justifyContent: "space-between",
                        }}
                        className="flex "
                      >
                        <div className="f-18">No Of Owners</div>

                        <div className="p-color f-20">
                          {activeCarDetails?.no_of_owners}
                        </div>
                      </div>
                    )}
                    <div
                      style={{ marginTop: 10, justifyContent: "space-between" }}
                      className="flex"
                    >
                      <div className="date f-18">Published :</div>
                      <div>{formatDate(activeCarDetails.created_at)}</div>
                    </div>
                    {activeCarDetails?.view_count > 0 && (
                      <div
                        style={{
                          marginTop: 10,
                          justifyContent: "space-between",
                        }}
                        className="flex"
                      >
                        <div className="date f-18">Total Page Views</div>
                        <div>{activeCarDetails?.view_count}</div>
                      </div>
                    )}
                    <VrsCarLinkShareComponent details={linkShareDetails} />

                    <div className="mt-30">
                      <div className="">
                        <div className="">
                          <CustomerForm />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                          borderBottom: " 1px solid #b4b4b7",
                        }}
                      ></div>
                      {activeCarDetails.description && (
                        <div style={{ marginBottom: 10 }}>
                          <h2
                            style={{ margin: "13px 6px 6px 0px" }}
                            className="f-bold"
                          >
                            Description
                          </h2>
                          <div className=" post-desc">
                            {activeCarDetails.description}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="t-a-c mt-10">
                    <div className="">
                      <h2 className="f-bold">Related Cars</h2>

                      <div
                        className={
                          isMobile ? "grid-container" : "grid-container-desktop"
                        }
                      >
                        {allCars?.map((post, i) => (
                          <div
                            className="grid-product-item left"
                            onClick={() => handleClick(post)}
                          >
                            <img
                              className="grid-product-item-image"
                              src={
                                post?.images && post.images.length > 0
                                  ? post.images[0].image
                                  : require("./../../assets/icons/no-photos.png")
                              }
                              alt={post?.name || "Product Image"}
                            />
                            <div className="grid-content">
                              <p className="grid-item-text">{post.name}</p>
                              <p className="grid-item-location">
                                {/* {post?.customer},{" "} */}
                              </p>
                            </div>

                            <div className="grid-item-price">
                              <PriceComponent
                                price={post.price}
                                priceType="L"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default ViewVrsCarsComponent;
