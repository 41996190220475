import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { useLocation, useNavigate } from "react-router-dom";
import FormBuilderComponent from "../../components/form-builder/form-builder";
import { editPaymentApi } from "./payment.service";
import { getPaymentForm } from "./payment-form";

const EditPaymentComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };

  const handleSubmit = async (values) => {
    // console.log(all);

    const resp = await editPaymentApi(state?.id, values);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/payment");
    }
  };

  // console.log({ all });

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Paymnet" backRoute="/payment" />
        <div style={{ paddingTop: 70, paddingLeft: 12, paddingRight: 12 }}>
          <FormBuilderComponent
            onSubmit={handleSubmit}
            inputs={getPaymentForm(state)}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditPaymentComponent;
