import { API } from "../../constants/api.constants";
import { resetUserTokenAction } from "../../reducers/app.reducer";
import { store } from "../../store";

const {
  PAYMENT_CREATE,
  PAYMENT_LIST,
  PAYMENT_EDIT,
  PAYMENT_DELETE,
  PAYMENT_DETAILS,
} = API;
export const getDataApi = async ({ orderBy, sortBy, skip, limit, filters }) => {
  return await fetch(
    `${PAYMENT_LIST}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      // console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const createPaymentAPI = async (payload) => {
  return await fetch(PAYMENT_CREATE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const editPaymentApi = async (id, payload) => {
  return await fetch(PAYMENT_EDIT + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const checkForExpiredToken = (message) => {
  if (
    message === "Token expired" ||
    message === "Invalid token" ||
    message === "Token refresh"
  ) {
    store.dispatch(resetUserTokenAction());
  }
};

export const deleteDataApi = async (ids) => {
  return await fetch(PAYMENT_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const paymentDetailsDataApi = async (id) => {
  return await fetch(PAYMENT_DETAILS + id, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
