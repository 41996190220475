export const BASE_URL = "https://vag-parts-api.triamiz.com/api";
export const BUCKET_URL = "https://vag-parts.s3.ap-south-1.amazonaws.com/v1";
// export const BASE_URL = "http://localhost:8000/api";

export const APP_NAME = "VAG BAY";

export const APP_URL = "https://vagbay.com/";

export const API = {
  LOGIN: `${BASE_URL}/login`,
  HOME: `${BASE_URL}/home`,
  ADMIN_INFO: `${BASE_URL}/admin-info`,
  CREATE_CATEGORY: `${BASE_URL}/category/create`,
  EDIT_CATEGORY: `${BASE_URL}/category/edit/`,
  DELETE_CATEGORY: `${BASE_URL}/category/delete`,
  GET_POST_BY_ID: `${BASE_URL}/post/`,
  GET_RELATED_POST_BY_ID: `${BASE_URL}/related-posts/`,
  GET_DEALERS: `${BASE_URL}/dealers`,

  UPLOAD_IMAGE: `${BASE_URL}/upload/image-file`,
  CATEGORY_ALL: `${BASE_URL}/category/all`,
  CATEGORY_LIST_ALL: `${BASE_URL}/category/getAll`,

  CREATE_POST: `${BASE_URL}/post/create`,
  POST_ALL: `${BASE_URL}/post/all`,
  EDIT_POST: `${BASE_URL}/post/edit/`,
  DELETE_POST: `${BASE_URL}/post/delete`,
  CUSTOMER_POST_LIST: `${BASE_URL}/post/getByCustomer/`,

  CREATE_QUICK_LINK: `${BASE_URL}/quick-link/create`,
  QUICK_LINK_LIST: `${BASE_URL}/quick-links`,
  EDIT_QUICK_LINK: `${BASE_URL}/quick-link/edit/`,
  DELETE_QUICK_LINK: `${BASE_URL}/quick-link/delete`,
  QUICK_LINK_ALL: `${BASE_URL}/quick-links/all`,

  CREATE_CUSTOMER: `${BASE_URL}/customers/create`,
  CUSTOMER_LIST: `${BASE_URL}/customers`,
  EDIT_CUSTOMER: `${BASE_URL}/customers/edit/`,
  DELETE_CUSTOMER: `${BASE_URL}/customers/delete`,
  CUSTOMERS_LIST_ALL: `${BASE_URL}/customers/list`,
  CUSTOMERS_AUTO_COMPLETE: `${BASE_URL}/customers/autoComplete`,

  CREATE_GROUP_BY: `${BASE_URL}/group-buy/create`,
  GROUP_BY_LIST: `${BASE_URL}/group-buy`,
  EDIT_GROUP_BY: `${BASE_URL}/group-buy/edit/`,
  DELETE_GROUP_BY: `${BASE_URL}/group-buy/delete/`,
  GROUP_BUY: `${BASE_URL}/group-buy/`,
  GROUP_BUY_PRODUCTS: `${BASE_URL}/group-buy/update-products/`,

  CUSTOMER_REGISTER: `${BASE_URL}/customer/register`,
  CUSTOMER_REGISTER_VERIFY: `${BASE_URL}/customer/register/verify`,
  GROUP_BUY_JOIN: `${BASE_URL}/group-buy/member/join/`,
  GROUP_BUY_LEAVE: `${BASE_URL}/group-buy/member/leave/`,
  GROUP_BUY_MEMBER_PAYMENT_DETAILS: `${BASE_URL}/group-buy/member/payment/`,
  GROUP_BUY_MEMBER_SAVE_PAYMENT_DETAILS: `${BASE_URL}/group-buy/member/payment/save/`,
  GROUP_BUY_JOIN_BY_ADMIN: `${BASE_URL}/group-buy/member/join-by-admin/`,
  GROUP_BUY_MEMBER_EDIT_PAYMENT_DETAILS: `${BASE_URL}/group-buy/member/payment/edit/`,
  GROUP_BUY_MEMBERS_DOWNLOAD: `${BASE_URL}/group-buy/member/download/`,

  CARS_CREATE: `${BASE_URL}/car/create`,
  CARS_ALL: `${BASE_URL}/cars/list`,
  CARS_EDIT: `${BASE_URL}/car/edit/`,
  CARS_DELETE: `${BASE_URL}/car/delete`,
  CARS_LIST: `${BASE_URL}/car`,
  CARS_CUSTOMER_REGISTER: `${BASE_URL}/car/register`,
  CARS_CUSTOMER_VERIFY: `${BASE_URL}/car/register/verify`,
  CAR_DATA: `${BASE_URL}/car/`,
  CAR_RELATED_LIST: `${BASE_URL}/related-cars/`,

  CREATE_PAGE_ENTRY: `${BASE_URL}/page-view-entry/create`,
  CARS_DOWNLOAD: `${BASE_URL}/cars/download`,
  POSTS_DOWNLOAD: `${BASE_URL}/posts/download`,

  FAQ_CREATE: `${BASE_URL}/faq/create`,
  FAQ_ALL: `${BASE_URL}/faq/list`,
  FAQ_EDIT: `${BASE_URL}/faq/edit/`,
  FAQ_DELETE: `${BASE_URL}/faq/delete`,
  FAQ_LIST: `${BASE_URL}/faq`,
  FAQ_TAGS: `${BASE_URL}/faq/tags`,
  FAQ_LIST_ALL: `${BASE_URL}/tags/getAllData`,
  FAQ_TAGS_AUTOCOMPLETE: `${BASE_URL}/faq/tags/autoComplete`,

  PAYMENT_CREATE: `${BASE_URL}/payment/create`,
  PAYMENT_LIST: `${BASE_URL}/payment`,
  PAYMENT_EDIT: `${BASE_URL}/payment/edit/`,
  PAYMENT_DELETE: `${BASE_URL}/payment/delete`,

  PAYMENT_DETAILS: `${BASE_URL}/payment/details/`,
};
