import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { BUCKET_URL } from "../../constants/api.constants";
import { createAPI, createPostAPI } from "./faq.service";
import { getAllDataApi, imageUploadAPI } from "../category/category.service";
import { useNavigate } from "react-router-dom";
import FaqForm from "./faq-form";

const FaqCreateComponent = () => {
  const [all, setAll] = useState({
    id: "",
    title: "",
    tags: [],
    customer_id: "",
  });
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const handleChange = async (name, value) => {
    console.log(name, value);

    if (typeof name === "object" && name !== null) {
      setAll({ ...all, customer_id: name.value });
    } else {
      setAll({ ...all, [name]: value });
    }
  };
  console.log(all);

  const handleSubmit = async () => {
    console.log(all);

    const resp = await createAPI(all);
    console.log({ resp });
    if (resp.status) {
      gotoScreen("/faq");
    } else alert(resp.message);
  };

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Add Faq" backRoute="/faq" />
        <FaqForm
          all={all}
          setAll={setAll}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </PageWrapperComponent>
  );
};

export default FaqCreateComponent;
