import { API } from "../../constants/api.constants";
import { resetUserTokenAction } from "../../reducers/app.reducer";
import { store } from "../../store";

const {
  FAQ_CREATE,
  FAQ_EDIT,
  FAQ_DELETE,
  FAQ_LIST,
  FAQ_TAGS,
  FAQ_LIST_ALL,
  FAQ_TAGS_AUTOCOMPLETE,
} = API;
export const getDataApi = async ({ orderBy, sortBy, skip, limit, filters }) => {
  return await fetch(
    `${FAQ_LIST}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}&${filters}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      // console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const createAPI = async (payload) => {
  return await fetch(FAQ_CREATE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const editApi = async (id, payload) => {
  return await fetch(FAQ_EDIT + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const checkForExpiredToken = (message) => {
  if (message === "Token expired" || message === "Invalid token") {
    store.dispatch(resetUserTokenAction());
  }
};

export const deleteDataApi = async (ids) => {
  return await fetch(FAQ_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const getAllFaqApi = async () => {
  return await fetch(FAQ_LIST_ALL, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const getAllFaqTagsApi = async () => {
  return await fetch(FAQ_TAGS, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          data: data.data,
          status: data.status,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong from login api",
        error,
      };
    });
};
export const tagsAutoCompleteApi = async (name) => {
  return await fetch(FAQ_TAGS_AUTOCOMPLETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app?.token,
    },
    body: JSON.stringify({ name }),
  })
    .then((res) => res.json())
    .then((response) => {
      // console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
