import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import { useNavigate } from "react-router-dom";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLocationDot,
  faPhone,
  faTrashCan,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import LoaderComponent from "../../components/loader/loader";
import {
  deleteDataApi,
  getDataApi,
  paymentDetailsDataApi,
} from "./payment.service";
import SearchComponent from "../../components/search/search";
import TabLayout from "../../components/tab-layout/tab-layout.component";
import { useSelector } from "react-redux";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import PaymentButton from "./payment-button";
// import
const PaymentPageComponent = () => {
  const navigate = useNavigate();
  const [all, setAll] = useState({ loading: true, search: "", amount: 399 });

  const [tableData, setTableData] = useState([]);
  const { user } = useSelector((state) => state.app);

  const gotoScreen = (url) => {
    navigate(url);
  };
  let params = {
    orderBy: "created_at",
    sortBy: "desc",
    skip: 0,
    limit: 200,
  };
  const onload = () => {
    getData();
  };
  console.log(user);

  const getData = async () => {
    if (user?.id) {
      const data = await paymentDetailsDataApi(user?.id);
      // console.log(data);
      // console.log(user);

      if (data.status) {
        setTableData(data.data);
        setAll({ ...all, loading: false, allTableData: data.data });
      } else setAll({ ...all, loading: false });
    } else {
      setAll({ ...all, loading: false });
    }
  };

  useEffect(() => {
    onload();
  }, []);
  // console.log(tableData);
  const handleChange = (e) => {
    // console.log(e.target.value);
    let value = e.target.value;

    setAll({ ...all, amount: value });
  };

  const generateTransactionId = () => {
    return `TXN${Date.now()}`;
  };

  const handleClickPay = () => {
    console.log("jjj");

    const UPI_ID = "vins374@okaxis";
    const payeeName = "VagBay";

    const transactionId = generateTransactionId();

    const upiURL2 = `intent://pay?pa=${UPI_ID}&pn=${payeeName}&tn=PlatformSupport&am=${all?.amount}&cu=INR&tr=${transactionId}#Intent;scheme=upi;end;`;

    // const upiUrl = `upi://pay?pa=vins374@okaxis&pn=VagBay&mc=1234&tid=0000001&tr=000001&tn=Payment%20for%20services&am=${all?.amount}&cu=INR`;

    window.location.href = upiURL2;
  };
  return (
    <PageWrapperComponent>
      <div className="page-container ">
        <AccountHeaderComponent name="Payment Details" backRoute="/account" />
        <div className="account-content">
          {/* {!all.loading && tableData.length === 0 && !user && ( */}
          <div className="container t-a-c">
            <EmptyBoxComponent
              image="vins-upi.jpeg"
              imageStyles={{
                marginTop: 25,
                width: "91%",
              }}
              message="Please make upi payment for platform support. For parts Rs.399 & For Cars Rs.1999"
            />

            <PaymentButton
              handleClickPay={handleClickPay}
              handleChange={handleChange}
              all={all}
            />
          </div>
          {/* )} */}

          {/* {all.loading && (
            <div className="t-a-c">
              <LoaderComponent />
            </div>
          )} */}

          {/* <div>
            <>
              {user ? (
                <div style={{ paddingTop: 30, padding: 20 }}>
                  <div className="customer-details-container">
                    <span className="customer-info">
                      <img
                        className="profile-image t-a-c"
                        src={require("./../../assets/icons/profile.png")}
                        alt="Profile"
                      />
                      <div className="customer-info-details">
                        <div className="customer-info-item">
                          <span className="info-label">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <span className="info-value">
                            {user?.name || "name"}
                          </span>
                        </div>
                        <div className="customer-info-item">
                          <span className="info-label">
                            {" "}
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <span className="info-value">
                            {user?.phone || "phone"}
                          </span>
                        </div>

                        <div className="customer-info-item">
                          <span className="info-label">
                            <FontAwesomeIcon icon={faLocationDot} />
                          </span>
                          <span
                            className="info-value"
                            style={{ marginLeft: "25px" }}
                          >
                            {user?.location || "location"}
                          </span>
                        </div>
                      </div>
                    </span>
                  </div>
                  <PaymentButton
                    handleClickPay={handleClickPay}
                    handleChange={handleChange}
                    all={all}
                  />
                  {tableData.length !== 0 && (
                    <>
                      <div className="customer-info-item">Payment's</div>

                      <div style={{ paddingTop: "10px" }}>
                        {tableData.map((item, i) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={i}
                          >
                            <span>
                              {item.amount}
                              <span
                                style={{
                                  marginTop: "-10px",
                                  marginLeft: "10px",
                                }}
                              >
                                {item.status == "1" && (
                                  <FaCheckCircle className="green-color capture-payment" />
                                )}
                              </span>
                            </span>
                            <span style={{ marginTop: "10px" }}>
                              {item.created_at}{" "}
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  {user && user?.role_name === "Admin" && (
                    <div
                      className="customer-details-container container"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="customer-info">
                        <img
                          className="profile-image t-a-c"
                          src={require("./../../assets/icons/profile.png")}
                          alt="Profile"
                        />
                        <div className="customer-info-details">
                          <div className="customer-info-item">
                            <span className="info-label">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                            <span className="info-value">
                              {user?.name || "Name not available"}
                            </span>
                          </div>

                          <div className="customer-info-item">
                            <span className="info-label">
                              <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <span className="info-value">
                              {user?.mobile || "Phone not available"}
                            </span>
                          </div>

                          <div className="customer-info-item">
                            <span className="info-label">
                              <FontAwesomeIcon icon={faUserTie} />
                            </span>
                            <span
                              className="info-value"
                              style={{ marginLeft: "25px" }}
                            >
                              {user?.role_name || "Role not available"}
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          </div> */}
        </div>
      </div>
      <TabLayout defaultTab={"payment"} />
    </PageWrapperComponent>
  );
};

export default PaymentPageComponent;
