import React from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { APP_URL } from "../../constants/api.constants";
import LinkShareButton from "../../components/post-share/link-share-button";

const VrsCarLinkShareComponent = ({ details }) => {
  return (
    <div className="contact-seller-mode">
      {details?.user && (
        <>
          <span
            className="contact-button contact-mode"
            style={{
              //   marginLeft: "30px",
              backgroundColor: "#65ce40 ",
            }}
          >
            <a
              href={`https://wa.me/+91${details?.phone.replace(
                /\D/g,
                ""
              )}?text=${encodeURIComponent(
                "Hi, I would like to know the price of this post."
              )} https://vagbay.com/vrs-for-sale/${details.link}`}
            >
              <FaWhatsapp style={{ width: 20, height: 20 }} color="white" />
            </a>
          </span>
          <span
            className="contact-button contact-mode"
            style={{ backgroundColor: "rgb(78 190 255)" }}
          >
            <a href={`tel:${details?.phone}`}>
              <FaPhone style={{ width: 20, height: 20 }} color="white" />
            </a>
          </span>
        </>
      )}

      <LinkShareButton url={APP_URL + "vrs-for-sale/" + details.link} />
    </div>
  );
};

export default VrsCarLinkShareComponent;
